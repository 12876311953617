<template>
  <div class="code-editor">
    <div
      v-resize-observer="onResize"
      class="code-container"/>
    <CyTooltip
      v-if="!!actionBtnIcon"
      left>
      <template #activator="{ on }">
        <span
          class="code-editor__action-btn-wrapper"
          v-on="on">
          <CyButton
            :aria-label="actionBtnTooltip"
            icon-only
            :icon="actionBtnIcon"
            class="code-editor__action-btn"
            theme="grey"
            @click="$emit('action-btn-clicked')"/>
        </span>
      </template>
      {{ actionBtnTooltip }}
    </CyTooltip>
  </div>
</template>

<script>
import ace from 'brace'
import 'brace/theme/monokai'
import 'brace/ext/searchbox'

export default {
  name: 'CyCodeEditor',
  props: {
    value: {
      type: String,
      required: true,
    },
    codeLang: {
      type: String,
      default: 'text',
    },
    theme: {
      type: String,
      default: 'monokai',
    },
    debounce: {
      type: Number,
      default: 0,
    },
    showPrintMargin: {
      type: Boolean,
      default: false,
    },
    showGutter: {
      type: Boolean,
      default: true,
    },
    actionBtnIcon: {
      type: String,
      default: '',
    },
    actionBtnTooltip: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    autoScroll: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editor: null,
    contentBackup: '',
  }),
  watch: {
    value (val) {
      if (this.contentBackup !== val) this.editor.setValue(val, 1)
    },
  },
  mounted () {
    this.editor = ace.edit(this.$el.querySelector('.code-container'))
    this.$emit('init', this.editor)

    this.editor.setShowPrintMargin(this.showPrintMargin)
    this.editor.renderer.setShowGutter(this.showGutter)

    this.editor.$blockScrolling = Infinity
    ace.config.setModuleUrl('ace/mode/yaml', require('brace/mode/yaml.js'))
    ace.config.setModuleUrl('ace/mode/json', require('brace/mode/json.js'))
    ace.config.setModuleUrl('ace/mode/sh', require('brace/mode/sh.js'))
    this.editor.getSession().setMode(`ace/mode/${this.codeLang}`)

    if (this.codeLang === 'json' || this.codeLang === 'yaml' || this.codeLang === 'hcl') {
      this.editor.getSession().setUseSoftTabs(true)
      this.editor.getSession().setTabSize(2)
    }

    this.editor.setTheme(`ace/theme/${this.theme}`)
    this.editor.setValue(this.value, 1)
    this.editor.setReadOnly(this.readOnly)

    if (this.debounce) {
      this.setContent = _.debounce(this.setContent, this.debounce)
    }

    this.editor.commands.addCommand({
      name: 'undoAllButInitState',
      bindKey: { win: 'Ctrl-Z', mac: 'Command-Z' },
      exec: (editor) => {
        const undoManager = editor.getSession().getUndoManager()
        if (_.$get(undoManager, '$undoStack', []).length > 1) editor.undo()
      },
      readOnly: true,
    })

    this.editor.on('change', this.onEditorChange)
    this.editor.on('blur', () => { this.$emit('blur') })
    this.editor.getSession().on('changeScrollTop', this.onScroll)
  },
  methods: {
    onEditorChange () {
      this.$emit('processing-content')
      this.setContent()
    },
    setContent () {
      const content = this.editor.getValue()
      // tiny hack to ensure the editor won't disappear when deleting all content in Edit pipeline
      this.$emit('input', content || ' ')
      this.contentBackup = content
      if (this.autoScroll) this.scrollToBottom()
      this.$emit('content-processed')
    },
    scrollToBottom () {
      const row = this.editor.session.getLength()
      this.editor.gotoLine(row, Infinity)
    },
    onResize () {
      this.editor.resize()
    },
    onScroll ($event) {
      const newHeight = this.editor.getSession().getScreenLength() * this.editor.renderer.lineHeight

      this.$emit('editor-scroll', $event, newHeight)
    },
  },
}
</script>

<style lang="scss" scoped>
.code-container,
.code-editor {
  width: 100%;
  height: 100%;
}

.code-editor {
  position: relative;

  &__action-btn-wrapper {
    position: absolute;
    z-index: 10;
    top: 16px;
    right: 24px;
  }

  &__action-btn ::v-deep {
    background-color: get-color("grey", $alpha: 0.2) !important;
    color: get-color("white") !important;
  }

  ::v-deep {
    .ace_editor .ace_marker-layer .ace_selection {
      background: get-color("grey", "dark-4");
    }

    .ace_editor .ace_active-line {
      background: #333;
    }
  }
}
</style>
